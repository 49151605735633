import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Nunito\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"variable\":\"--font-jetbrainsMono\"}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/popups/SocketConnectionPopup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/shared/providers/animate-transitions/AnimationTransition.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/shared/providers/animate-transitions/PageTransition.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProvider"] */ "/app/src/shared/providers/QueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SocketProvider"] */ "/app/src/shared/providers/SocketProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/src/shared/providers/ThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/app/src/shared/providers/TooltipProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/styles/globals.css");
