'use client';

import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useSocket } from '@/shared/providers/SocketProvider';
import { URLS } from '@/shared/constants';
const SocketConnectionPopup = () => {
  const socket = useSocket(URLS.API.BASE);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  useEffect(() => {
    if (!socket) return;
    let timer: NodeJS.Timeout;
    const handleConnect = () => {
      timer = setTimeout(() => {
        setShowPopup(false);
      }, 500);
    };
    const handleDisconnect = () => {
      timer = setTimeout(() => {
        setShowPopup(true);
      }, 500);
    };
    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);
    return () => {
      socket.off('connect', handleConnect);
      socket.off('disconnect', handleDisconnect);
      clearTimeout(timer);
    };
  }, [socket]);
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="SocketConnectionPopup" data-sentry-source-file="SocketConnectionPopup.tsx">
			{showPopup && <motion.div initial={{
      opacity: 0,
      y: 50
    }} animate={{
      opacity: 1,
      y: 0
    }} exit={{
      opacity: 0,
      y: 50
    }} transition={{
      duration: 0.5,
      delay: 1
    }} className="fixed bottom-2 z-50 flex w-full items-center justify-center">
					<div className="flex rounded-3xl border-2 border-border p-3 transition-all duration-300 ease-in-out">
						<motion.div className="loader" initial={{
          rotate: 0
        }} animate={{
          rotate: 360
        }} transition={{
          duration: 1,
          repeat: Infinity,
          ease: 'linear'
        }} style={{
          width: '24px',
          height: '24px',
          border: '4px solid rgba(255, 255, 255, 0.3)',
          borderTop: '4px solid hsl(var(--primary))',
          borderRadius: '50%',
          marginRight: '8px'
        }} />
						<p>Connection lost! Reconnecting..</p>
					</div>
				</motion.div>}
		</AnimatePresence>;
};
export default SocketConnectionPopup;