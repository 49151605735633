'use client';

import { PropsWithChildren, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
export const QueryProvider = ({
  children
}: PropsWithChildren) => {
  const [client] = useState(new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  }));
  return <QueryClientProvider client={client} data-sentry-element="QueryClientProvider" data-sentry-component="QueryProvider" data-sentry-source-file="QueryProvider.tsx">{children}</QueryClientProvider>;
};