'use client';

import { AnimatePresence, motion } from 'framer-motion';
import { usePathname } from 'next/navigation';
import Animation from './Animation';
import { useEffect, useState } from 'react';
const AnimationTransition = () => {
  const pathname = usePathname();
  const [isAnimating, setIsAnimating] = useState(true);
  useEffect(() => {
    if (isAnimating) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isAnimating]);
  return <>
			<AnimatePresence mode="wait" data-sentry-element="AnimatePresence" data-sentry-source-file="AnimationTransition.tsx">
				<div className="" key={pathname}>
					<div className="pointer-events-none fixed left-0 right-0 top-0 z-40 flex h-screen w-screen">
						<Animation data-sentry-element="Animation" data-sentry-source-file="AnimationTransition.tsx" />
					</div>

					<motion.div className="pointer-events-none fixed top-0 h-screen w-screen bg-primary" initial={{
          opacity: 1
        }} animate={{
          opacity: 0,
          transition: {
            delay: 1,
            duration: 0.4,
            ease: 'easeInOut'
          }
        }} onAnimationComplete={() => setIsAnimating(false)} onAnimationStart={() => setIsAnimating(true)} data-sentry-element="unknown" data-sentry-source-file="AnimationTransition.tsx"></motion.div>
				</div>
			</AnimatePresence>
		</>;
};
export default AnimationTransition;