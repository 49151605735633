import { API_URL } from '../lib/axios';

const URLS = {
	API: {
		BASE: API_URL,
		NOTIFICATIONS: new URL('/notifications', API_URL).toString(),
	},
	SOCIALS: {
		TELEGRAM: 'https://t.me/ObjectNimesis',
		DISCORD: 'https://discord.gg/QhaASe5zBN',
	},
	AVATAR_URL: (id: string) =>
		new URL(`/users/avatars/${id}`, API_URL).toString(),
};

export { URLS };
