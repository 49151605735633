import axios from 'axios';
import useAuthStore from './store';

export interface IApiError {
	code: string;
	message: string;
}

export const API_URL = process.env.API_URL ?? "http://localhost:4000";

const $api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

const store = useAuthStore.getState();

$api.interceptors.request.use((config) => {
	if (store.token) config.headers.Authorization = `Bearer ${store.token}`;

	return config;
});

$api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const original = error.config;

    if (error.response?.status === 401) {
      if (!original._retry) {
        original._retry = true;

        try {
          const { data } = await axios.post<{ token: string }>(
            `${API_URL}/auth/refresh`,
            {},
            { withCredentials: true }
          );

          store.setToken(data.token);

          $api.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data.token}`;

          return $api(original);
        } catch (err) {
          return Promise.reject(err);
        }
      }

      if (typeof window !== "undefined") {
        return window.location.replace("/auth/login");
      }
    }

    return Promise.reject(error);
  }
);

export default $api;
