'use client';

import { motion } from 'framer-motion';
const stairAnimation = {
  inital: {
    right: '0%'
    // opacity: 1,
  },
  animate: {
    opacity: 0
    // right: '100%',
  },
  exit: {
    // right: ['100%', '0%'],
  }
};
const reverseIndex = (index: number) => {
  const totalSteps = 12;
  return totalSteps - index - 1;
};
const Animation = () => {
  return <>
			<motion.div variants={stairAnimation} initial="initial" animate="animate" exit="exit" transition={{
      duration: 1.8,
      ease: 'easeInOut',
      delay: reverseIndex(0) * 0.1
    }} className="relative flex h-full w-full items-center justify-center bg-[#000000]" data-sentry-element="unknown" data-sentry-source-file="Animation.tsx">
				<motion.svg animate={{
        rotate: [0, 25, 0],
        scale: [1, 1.3, 1]
      }} transition={{
        duration: 0.6,
        ease: 'easeInOut',
        repeat: Infinity,
        repeatDelay: 0.3
      }} width="208" height="231.3" viewBox="0 0 624 694" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="unknown" data-sentry-source-file="Animation.tsx">
					<g filter="url(#filter0_i_725_13)" data-sentry-element="g" data-sentry-source-file="Animation.tsx">
						<path d="M267 12.9808C294.846 -3.0962 329.154 -3.09619 357 12.9808L578.769 141.019C606.615 157.096 623.769 186.808 623.769 218.962V475.038C623.769 507.192 606.615 536.904 578.769 552.981L357 681.019C329.154 697.096 294.846 697.096 267 681.019L45.2309 552.981C17.3848 536.904 0.230865 507.192 0.230865 475.038L0.230865 218.962C0.230865 186.808 17.3848 157.096 45.2309 141.019L267 12.9808Z" fill="white" data-sentry-element="path" data-sentry-source-file="Animation.tsx" />
					</g>
					<defs data-sentry-element="defs" data-sentry-source-file="Animation.tsx">
						<filter id="filter0_i_725_13" x="0.230957" y="0.923096" width="633.538" height="702.154" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB" data-sentry-element="filter" data-sentry-source-file="Animation.tsx">
							<feFlood flood-opacity="0" result="BackgroundImageFix" data-sentry-element="feFlood" data-sentry-source-file="Animation.tsx" />
							<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" data-sentry-element="feBlend" data-sentry-source-file="Animation.tsx" />
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" data-sentry-element="feColorMatrix" data-sentry-source-file="Animation.tsx" />
							<feOffset dx="10" dy="10" data-sentry-element="feOffset" data-sentry-source-file="Animation.tsx" />
							<feGaussianBlur stdDeviation="17.5" data-sentry-element="feGaussianBlur" data-sentry-source-file="Animation.tsx" />
							<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" data-sentry-element="feComposite" data-sentry-source-file="Animation.tsx" />
							<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" data-sentry-element="feColorMatrix" data-sentry-source-file="Animation.tsx" />
							<feBlend mode="normal" in2="shape" result="effect1_innerShadow_725_13" data-sentry-element="feBlend" data-sentry-source-file="Animation.tsx" />
						</filter>
					</defs>
				</motion.svg>
			</motion.div>
		</>;
};
export default Animation;