'use client';

import { TooltipProvider as TooltipProviderBase } from '@/components/ui/tooltip';
interface TooltipProviderProps {
  children: React.ReactNode;
}
export function TooltipProvider({
  children
}: TooltipProviderProps) {
  return <TooltipProviderBase data-sentry-element="TooltipProviderBase" data-sentry-component="TooltipProvider" data-sentry-source-file="TooltipProvider.tsx">{children}</TooltipProviderBase>;
}