'use client';

import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAuth } from '@/shared/hooks/useAuth';
import { URLS } from '@/shared/constants';
interface SocketContextType {
  getSocket: (host: string, namespace: string) => Socket | null;
}
const SocketContext = createContext<SocketContextType | undefined>(undefined);
export const SocketProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const {
    token,
    refresh
  } = useAuth();
  const [sockets, setSockets] = useState<Map<string, Socket>>(new Map());
  useEffect(() => {
    // Close all existing sockets when the token changes
    const closeSockets = () => {
      sockets.forEach(socket => socket.close());
      setSockets(new Map());
    };
    closeSockets();
    return () => closeSockets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  const getSocket = (host: string, namespace: string): Socket | null => {
    if (sockets.has(namespace)) {
      return sockets.get(namespace) || null;
    }
    const path = new URL(host);
    console.log({
      host: new URL(namespace, path).toString(),
      path: path.pathname === '/' ? '/socket.io' : `${path.pathname}/socket.io`
    });
    const newSocket = io(new URL(namespace, path).toString(), {
      path: path.pathname === '/' ? '/socket.io' : `${path.pathname}/socket.io`,
      auth: {
        token: `Bearer ${token}`
      },
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 2500,
      reconnectionDelayMax: 5000
    });
    newSocket.connect();
    setSockets(prev => new Map(prev).set(namespace, newSocket));
    return newSocket;
  };
  return <SocketContext.Provider value={{
    getSocket
  }} data-sentry-element="unknown" data-sentry-component="SocketProvider" data-sentry-source-file="SocketProvider.tsx">
			{children}
		</SocketContext.Provider>;
};
export const useSocket = (host: string, namespace: string = ''): Socket | null => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context.getSocket(host, namespace);
};