'use client';

import { AnimatePresence } from 'framer-motion';
import { usePathname } from 'next/navigation';
import { FC, ReactNode } from 'react';
import { motion } from 'framer-motion';
interface Props {
  children?: ReactNode;
}
const PageTransition: FC<Props> = ({
  children
}) => {
  const pathname = usePathname();
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="PageTransition" data-sentry-source-file="PageTransition.tsx">
			<div key={pathname}>
				<motion.div initial={{
        opacity: 1
      }} animate={{
        opacity: 0,
        transition: {
          delay: 1,
          duration: 0.4,
          ease: 'easeInOut'
        }
      }} className="pointer-events-none fixed top-0 h-screen w-screen bg-black" data-sentry-element="unknown" data-sentry-source-file="PageTransition.tsx" />
				{children}
			</div>
		</AnimatePresence>;
};
export default PageTransition;