"use client";

import $api, { IApiError } from "@/shared/lib/axios";
import { AxiosError } from "axios";
import useAuthStore, { IUser } from "../lib/store";

interface IResult<T> {
	data?: T;
	error?: IApiError;
}

export const useAuth = () => {
  const store = useAuthStore.getState();

  const me = async (): Promise<IResult<IUser>> => {
    try {
      const response = await $api.get<IUser>("/users/@me");

      store.setUser(response.data);

      return { data: response.data };
    } catch (e) {
      const error = e as AxiosError<IApiError>;
      return {
        error: error.response?.data,
      };
    }
  };

  const logout = async () => {
    store.logout();

    try {
      await $api.post("/auth/logout");
    } catch (error) {
      return;
    }
  };

  const register = async ({
    email,
    username,
    password,
  }: {
    email: string;
    username: string;
    password: string;
  }): Promise<IResult<{ token: string }>> => {
    try {
      const response = await $api.post<{
        token: string;
        user: IUser;
      }>("/auth/register", {
        email,
        username,
        password,
      });

      store.setToken(response.data.token);
      store.setUser(response.data.user);
      return {
        data: response.data,
      };
    } catch (e) {
      const error = e as AxiosError<IApiError>;
      return {
        error: error.response?.data,
      };
    }
  };

  const refresh = async () => {
    try {
      const response = await $api.post<{ token: string }>("/auth/refresh");

      store.setToken(response.data.token);
    } catch (e) {
      const error = e as AxiosError;

      if (error.status === 401) {
        store.logout();
      }

      throw error;
    }
  };

  const login = async (data: {
    email: string;
    password: string;
  }): Promise<IResult<{ token: string; user: IUser }>> => {
    try {
      const response = await $api.post<{ token: string; user: IUser }>(
        "/auth/login",
        {
          email: data.email,
          password: data.password,
        }
      );

      store.setToken(response.data.token);
      store.setUser(response.data.user);

      return { data: response.data };
    } catch (e) {
      const error = e as AxiosError<IApiError>;
      return { error: error.response?.data };
    }
  };

  return {
    logout,
    login,
    refresh,
    register,
    me,
    token: store.token,
    user: store.user,
    isAuthenticated: !!store.token,
  };
};
