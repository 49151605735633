import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export interface IUser {
  id: number;
  objectnimesis: number;
  username: string;
  display: string;
  avatar?: string;

  gender: {
    id: number;
    visibility: string;
    gender: "male" | "female" | "other";
  } | null;
  birthdate: { id: number; date: string; visibility: string } | null;
}

interface IAuthStore {
	user: IUser | null;
	token: string | null;

	setUser: (data: IUser | null) => void;
	setToken: (token: string | null) => void;
	logout: () => void;
}

const useAuthStore = create(
	persist<IAuthStore>(
		(set, get) => ({
			user: null,
			token: null,
			setUser: (data: IUser | null) => {
				set({ user: data });
			},
			setToken: (token) => {
				set({ token });
			},
			logout: () => {
				set({ user: null, token: null });
			},
		}),
		{
			name: 'objectnimesis',
			storage: createJSONStorage(() => localStorage),
		},
	),
);

export default useAuthStore;
